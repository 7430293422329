import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

export function JobList({ data }) {
  const activeJobs = data.filter((job) => {
    return new Date(job.node.frontmatter.due_date) >= new Date();
  });

  return activeJobs.length > 0 ? (
    <div>
      <h2 className="font-bold text-3xl pb-5">Current Openings</h2>

      <table className="table-auto w-full">
        <thead>
          <tr className="text-left">
            <th className="px-4 py-2 border">Role</th>
            <th className="px-4 py-2 border">Team</th>
            <th className="px-4 py-2 border">Location</th>
          </tr>
        </thead>
        <tbody>
          {activeJobs.map((job) => (
            <tr key={job.node.frontmatter.title}>
              <td className="px-4 py-2 border capitalize">
                <Link to={job.node.fields.slug} className="text-blue-600">
                  {job.node.frontmatter.title}
                </Link>
              </td>
              <td className="px-4 py-2 border">{job.node.frontmatter.team}</td>
              <td className="px-4 py-2 border">
                {job.node.frontmatter.location}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div>
      <p className="font-bold italic">Currently there are no open positions.</p>
      <p className="pt-10">
        Do you feel like you belong working with CM Teleservices, but we just
        don't have your dream job posted? No problem, just reach out. Email us
        at <span className="font-medium italic">recruitment@cmteleservices.com.np</span>.
      </p>
    </div>
  );
}

export default function JobBoard() {
  return (
    <StaticQuery
      query={graphql`
        query JobQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: frontmatter___post_date }
          ) {
            edges {
              node {
                html
                fields {
                  slug
                }
                frontmatter {
                  title
                  team
                  location
                  due_date
                  post_date
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
          <JobList data={data.allMarkdownRemark.edges} />
        </div>
      )}
    />
  );
}
