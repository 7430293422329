import React from "react";
import JobBoard from "../components/jobBoard";
import Layout from "../components/layout";
import SEO from "../components/seo";

import bannerImage from "../images/careerBanner.jpeg";
import discussionImage from "../images/discussion.jpg";

export default function Careers() {
  return (
    <Layout>
      <SEO title="Careers" />
      <div
        className="bg-cover bg-center h-screen flex items-end pb-12"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="max-w-md pl-6 md:pl-24 text-black">
          <h2 className="font-bold text-5xl ">Careers</h2>
          <p className="font-medium">
            We are always eager to meet fresh talent, so check our open
            positions.
          </p>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-6 lg:px-0 my-10">
        <div className="py-5">
          <h2 className="font-bold text-3xl capitalize">
            Join to be a part of ambitious team.
          </h2>
          <p className="font-light text-sm capitalize">
            We do, what we love to do.
          </p>
        </div>
        <div className="flex flex-wrap md:-mx-5 lg:-mx-10 my-10">
          <div className="w-full md:w-1/2 md:px-5 lg:px-10">
            <img src={discussionImage} alt="company teams dicussion" />
            <p className="hidden">
              <span>
                Photo by{" "}
                <a href="https://unsplash.com/@mimithian?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                  Mimi Thian
                </a>{" "}
                on{" "}
                <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                  Unsplash
                </a>
              </span>
            </p>
          </div>
          <div className="w-full md:w-1/2 md:px-5 lg:px-10">
            <h2 className="font-bold text-3xl mb-5 capitalize">
              Company Culture
            </h2>
            <p className="mb-5">
              Work smart or work hard, just get the job done! We are
              goal-oriented, perfection striving, highly motivated team of
              individuals who are always seeking team members with similar work
              ethics. We believe in providing our employees with creative
              outlet, canvassing their individual ingenuity for the benefit of
              the entire team. We believe in challenging ourselves, leaving
              behind a trail of excellence in all our professional endeavors. We
              support each other to strengthen our team, share prosperity and
              celebrate success.
            </p>
            <p className="mb-5">
              We are an equal opportunity employer and have zero tolerance for
              any form of discrimination within our place of work. At CM, we
              cherish the following qualities in our teammates
            </p>
            <ul className="grid grid-flow-row grid-cols-2 grid-rows-4">
              <li>Sincerity</li>
              <li>Credibility</li>
              <li>Responsibility</li>
              <li>Promptness</li>
              <li>Innovation</li>
              <li>Collaboration</li>
              <li>Dedication</li>
            </ul>
          </div>
        </div>
        <div className="my-20">
          <div className="my-10 text-center">
            <h3 className="font-bold text-3xl uppercase">Life at CM</h3>
            <p className="font-thin text-xl max-w-3xl mx-auto py-2">
              At CM Teleservices, we celebrate our work, our clients, and our
              people. CM Teleservices has a small group of tight-knit teams
              working cross-functionally, learning from each other, and not
              being afraid to take on grand challenges.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 col-gap-10 row-gap-5">
            <div className="">
              <h3 className="font-medium text-xl mb-2">Flexible Schedule</h3>
              <p>
                Make and Work at your own schedule. It’s about what you
                accomplish rather than how long you work
              </p>
            </div>
            <div>
              <h3 className="font-medium text-xl mb-2">
                Diversity & Inclusion
              </h3>
              <p>
                CM Teleservices is committed to making diversity and inclusion
                part of building our workforce.
              </p>
            </div>
            <div>
              <h3 className="font-medium text-xl mb-2">
                Flexible Paid Time Off
              </h3>
              <p>
                We support team members all times, may it be for happy occasions
                or time of need.
              </p>
            </div>
            <div>
              <h3 className="font-medium text-xl mb-2">Insurance</h3>
              <p>
                We are concerned about safety and well-being of our employees
                and provide insurance for all our employees.
              </p>
            </div>
          </div>
        </div>

        <div className="my-20">
          <JobBoard />
        </div>
      </div>
    </Layout>
  );
}

